import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const TimelineEventElement = ({ organization, date, eventTitle, jobdesc, iconSrc, iconAlt, iconWidth }) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{
      background: `linear-gradient(to right,  rgb(147, 62, 129), rgb(73, 46, 158))`,
      color: '#fff',
    }}
    date={<>{organization} <br /> {date}</>}
    iconStyle={{
      background: `linear-gradient(to right,  rgb(147, 62, 129), rgb(73, 46, 158))`,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    icon={<img src={iconSrc} alt={iconAlt} style={{ width: iconWidth, height: 'auto' }} />}
  >
    <h5 className="vertical-timeline-element-title">{jobdesc}</h5>
    <h6 className="vertical-timeline-element-subtitle">{eventTitle}</h6>
  </VerticalTimelineElement>
);