import { useState } from "react";
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import banten from "../../assets/img/Pitch.png";

import "yet-another-react-lightbox/styles.css";
import YoutubeData from "../data/youtubeData";
import { YouTubeEmbed } from 'react-social-media-embed';
import PitchData from "../data/pitchData";

const CircleContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Pitching = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index, item) => setIndex(index);

  return (
    <Container>
      <div className="detail"></div>
      <Row className="align-items-center justify-content-center" style={{ marginTop: "100px" }}>
        <Col xs={12} md={8} lg={6}>
          <CenteredContent>
            <CircleContainer>
              <CircleImage src={banten} alt="Your Image" />
            </CircleContainer>
            <h1>Product Pitch</h1>
            <p>Video Editor</p>
          </CenteredContent>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {PitchData.map((videos, index) => (
          <Col key={index} xs={12} md={6} lg={6}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <YouTubeEmbed url={videos.link} width="100%" />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Pitching;
