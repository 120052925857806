import finference from "../../assets/img/finference.png";
import banten from "../../assets/img/Banten.png";
import asd from "../../assets/img/asd.png";
import stem from "../../assets/img/stem.png";
import innofair from "../../assets/img/innofair.png";
import icn from "../../assets/img/ICN.png";
import apo from "../../assets/img/apo.png";

const organizations = [
    {
        date: "October 2019 - Present",
        title: "Komunitas ARSA Banten",
        jobdesc: "Media and Documentation Coordinator",
        iconSrc: banten,
        iconAlt: "Apple Icon",
        iconWidth: "50px"
    },
    {
        date: "March 2021 - December 2021",
        title: "Finference 2021",
        jobdesc: "Multimedia Coordinator",
        iconSrc: finference,
        iconAlt: "bca Icon",
        iconWidth: "60px"
    },
    {
        date: "March 2021 - October 2021",
        title: "Actuarial Science Day 2021",
        jobdesc: "Documentation Coordinator",
        iconSrc: asd,
        iconAlt: "apple Icon",
        iconWidth: "60px"
    },
    {
        date: "August 2020 - July 2021",
        title: "STEM Prasetiya Mulya Innovation Student Organization (SISO) 2020/2021",
        jobdesc: "Documentation Coordinator",
        iconSrc: stem,
        iconAlt: "apple Icon",
        iconWidth: "50px"
    },
    {
        date: "October 2019 - September 2020",
        title: "Innofair 2020",
        jobdesc: "Documentation Division",
        iconSrc: innofair,
        iconAlt: "apple Icon",
        iconWidth: "50px"
    },
    {
        date: "October 2019 - August 2020",
        title: "Indonesia Culture National 2020",
        jobdesc: "Documentation Division",
        iconSrc: icn,
        iconAlt: "apple Icon",
        iconWidth: "40px"
    },
    {
        date: "August 2019 - July 2020",
        title: "Apo Chromatics Universitas Prasetiya Mulya",
        jobdesc: "Member of Photograpy Organization",
        iconSrc: apo,
        iconAlt: "apple Icon",
        iconWidth: "40px"
    },
];

export default organizations;
