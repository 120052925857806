const MaheswaraData = [
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7358793360309554437?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7360893918570958086?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7359416274834951430?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7361273613598739718?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7360594037692271878?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7359038854919228677?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7358695962728058117?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7358418397303950598?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7358062759847742726?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7353230040345136390?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7352744249336679685?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7351369760690654470?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7351003216827452678?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@weddingwithmaheswara/video/7350609171168906502?is_from_webapp=1&sender_device=pc"
  },
  
  ];
  
export default MaheswaraData;
  