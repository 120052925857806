import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import NoteToday from "../assets/img/Note-Today.png";
import banksampah from "../assets/img/banksampah.png";
import STEMPreneur from "../assets/img/stempreneur.png";
import simantap from "../assets/img/simantap.png";
import arsabanten from "../assets/img/arsabanten.png";
import nextgen from "../assets/img/nextgen.png";
import reels from "../assets/img/ReelsTiktok.png"
import podcast from "../assets/img/VideoPodcast.png"
import pitch from "../assets/img/Pitch.png"

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { PhotographyCard } from "./PhotographyCard";
import { VideoCard } from "./VideoCard";

export const Projects = () => {

  const projects = [
    {
      title: "NoteToday",
      description: "iOS Development: Note-taking for Linguistic",
      imgUrl: NoteToday,
      goto: 'https://apps.apple.com/id/app/note-today/id6470893885',
      link: "AppStore"
    },
    {
      title: "Bank Sampah Digital",
      description: "Android Development",
      imgUrl: banksampah,
      goto: "https://play.google.com/store/apps/details?id=com.stempreneur.appbsd&pcampaignid=web_share",
      link: "PlayStore"
    },
    {
      title: "STEMPreneur Center",
      description: "Web Development",
      imgUrl: STEMPreneur,
      goto: "https://preneur-stem.prasetiyamulya.ac.id/",
      link: "Visit Website"
    },
    {
      title: "SIMANTAP",
      description: "Web Developmet: Dashboard for Degree Audit",
      imgUrl: simantap,
      goto: "https://simantap.prasetiyamulya.ac.id/",
      link: "Visit Website"
    },
    {
      title: "Komunitas ARSA Banten",
      description: "Web Developmet: Social Community",
      imgUrl: arsabanten,
      goto: "https://komunitasarsabanten.wordpress.com/",
      link: "Visit Website"
    },
    {
      title: "Nextgen",
      description: "Web Developmet",
      imgUrl: nextgen,
      goto: "https://nextgen.web.id",
      link: "Visit Website"
    },
  ];

  const photography = [
    {
      title: "Kalfor Youth Innovators 2023",
      description: "15 - 16 January 2024",
      imgUrl: "1oTWGkmI4-fl6gVVOVFdOAAsPHHXq0y_q",
      goto: "/Kalfor-Youth-Innovators-2024",
      link: "Show More"
    },
    {
      title: "Aku Sehat 6",
      description: "23 December 2023",
      imgUrl: "18JzM7iUiCw9oKRLjbHa-74XMTTDTI_Pj",
      goto: "/Aku-Sehat-6",
      link: "Show More"
    },
    {
      title: "Demo Day IT PLN Ideathon 2023",
      description: "21 December 2023",
      imgUrl: "1t00MjEYzEUCYv4KMMZ2nzNtA2_khrjub",
      goto: "/DemoDay-IT-PLN-Ideathon-2023",
      link: "Show More"
    },
    {
      title: "Sharing n Fun Educating 7",
      description: "23 - 24 September 2023",
      imgUrl: "1573ow_LWptT3il9iZKD3hTNx-mtXyXNj",
      goto: "/Sharing-n-Fun-Educating-7",
      link: "Show More"
    },
    {
      title: "SAFE Pelita",
      description: "15 - 16 April 2023",
      imgUrl: "11uyRWwKgLjk-mQqUB5hWlCeH-MaxQx0F",
      goto: "/SAFE-Pelita",
      link: "Show More"
    },
    {
      title: "Gebrag Ramadhan",
      description: "7 April 2023",
      imgUrl: "1j6ynLANiVsrbH1GZsauBpO4wEeXgeZjM",
      goto: "/Gebrag-Ramadhan",
      link: "Show More"
    },
  ];


  const video = [
    {
      title: "Social Media",
      description: "Instagram and Tiktok Video Editor",
      imgUrl: reels,
      goto: "/Video-Editor/Social-Media",
      link: "Show More"
    },
    {
      title: "Podcast",
      description: "Audio and Visual Editor for Video Podcast",
      imgUrl: podcast,
      goto: "/Video-Editor/Podcast",
      link: "Show More"
    },
    {
      title: "Product Pitch",
      description: "-",
      imgUrl: pitch,
      goto: "/Video-Editor/Product-Pitch",
      link: "Show More"
    },
    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>As a software developer, my projects reflect a blend of technical expertise and artistic flair. From crafting clean and robust code for web and mobile applications to capturing meaningful moments through photography, I seamlessly integrate aesthetics into user interfaces.</p> 
                <p> Additionally, my proficiency in video editing adds a dynamic storytelling layer to my work. This holistic approach, showcased in my portfolio, demonstrates a commitment to pushing creative boundaries, creating a seamless fusion of technology and artistry.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Software</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Photo</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Video</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          photography.map((project, index) => {
                            return (
                              <PhotographyCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          video.map((project, index) => {
                            return (
                              <VideoCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
