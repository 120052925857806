const PitchData = [
  {
    link: "https://www.youtube.com/watch?v=lgZmfQLhxZw",
  },
  {
    link: "https://www.youtube.com/watch?v=NibvOcntdL4",
  },

];
  
export default PitchData;
  