import { useState } from "react";
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import banten from "../../assets/img/ReelsTiktok.png";
import belvic from "../../assets/img/belvic.jpeg";
import maheswara from "../../assets/img/maheswara.jpg";
import kanaya from "../../assets/img/kanaya.jpeg";
import sakulaundry from "../../assets/img/sakulaundry.jpeg";
import selca from "../../assets/img/selca.jpg";
import deeptalk from "../../assets/img/deeptalk1.png";
import arsabanten from "../../assets/img/arsa.jpeg";
import evoria from "../../assets/img/evoria.jpeg";
import toyago from "../../assets/img/toyago.jpeg";
import sorry from "../../assets/img/sorry.png";
import { InstagramView } from "./instagramView";
import instagramData from "../data/instagramData";

import "yet-another-react-lightbox/styles.css";

const CircleContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleContainerBrand = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 80%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto; /* Center the image within the circular container */
`;

const CircleImageBrand = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto; /* Center the image within the circular container */
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SocialMedia = () => {
    const [index, setIndex] = useState(-1);
    const handleClick = (index, item) => setIndex(index);
    return (
      <Container>
        <div className="detail"></div>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "100px" }}
        >
            <Col xs={12} md={8} lg={30}>
                <CenteredContent>
                    <CircleContainer>
                        <CircleImage src={banten} alt="Your Image" />
                    </CircleContainer>
                    <h1>Social Media</h1>
                    <p>Video Editor & Videographer</p>
                </CenteredContent>
            </Col>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "100px" }}>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                      <a href="/Video-Editor/Social-Media/Belvic">
                        <CircleContainerBrand>
                            <CircleImageBrand src={belvic} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Belvic</h3>
                      <p>Skincare</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                    <a href="/Video-Editor/Social-Media/Maheswara">
                        <CircleContainerBrand>
                            <CircleImageBrand src={maheswara} alt="Your Image" />
                        </CircleContainerBrand>
                    </a>
                      <h3>Maheswara</h3>
                      <p>Wedding Planner</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                      <a href="/Video-Editor/Social-Media/Kanaya">
                        <CircleContainerBrand>
                            <CircleImageBrand src={kanaya} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Kanaya</h3>
                      <p>Skincare</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                      <a href="/Video-Editor/Social-Media/Saku-Laundry">
                        <CircleContainerBrand>
                            <CircleImageBrand src={sakulaundry} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Saku Laundry</h3>
                      <p>Applications</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                      <a href="/Video-Editor/Social-Media/Selca">
                        <CircleContainerBrand>
                            <CircleImageBrand src={selca} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Selca</h3>
                      <p>Skincare</p>
                  </CenteredContent>
              </Row>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "100px"}}>
          <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                      <a href="/Video-Editor/Social-Media/DeepTalk">
                        <CircleContainerBrand>
                            <CircleImageBrand src={deeptalk} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      
                      <h3>Deep Talk</h3>
                      <p>Podcast</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                  <a href="/Video-Editor/Social-Media/Evoria">
                        <CircleContainerBrand>
                            <CircleImageBrand src={evoria} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Evoria</h3>
                      <p>Event Orchestrator</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                  <a href="/Video-Editor/Social-Media/ARSA-Banten">
                        <CircleContainerBrand>
                            <CircleImageBrand src={arsabanten} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Komunitas ARSA Banten</h3>
                      <p>Community</p>
                  </CenteredContent>
              </Row>
              <Row style={{marginLeft: "20px", marginRight: "20px"}}>
                  <CenteredContent>
                  <a href="/Video-Editor/Social-Media/Toyago">
                        <CircleContainerBrand>
                            <CircleImageBrand src={toyago} alt="Your Image" />
                        </CircleContainerBrand>
                      </a>
                      <h3>Toyago</h3>
                      <p>Toys</p>
                  </CenteredContent>
              </Row>
          </div>
            {/* <Row>
                {
                  instagramData.map((videos, index) => {
                    return (
                      <InstagramView
                        key={index}
                        {...videos}
                      />
                    )
                  })
                }
            </Row> */}
        </Row>
      </Container>
    );
  };
  

export default SocialMedia;
