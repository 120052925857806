const GebragImg = [
  "1Nndff0FA4uetJHVl6npmdhYt_NrgmOji",
  "1dm2UXpG4QXqHr0bhTZr8cgxbyA3_Mcnc",
  "1nmpWUIGf34u4Gn0TU9y7AZjdMgFnGke4",
  "1XAY5TTeF8SKVvHFsGdYmU_Onv_XFPqHd",
  "1bWwrGCd12QVD0ObVV4cprl2Xtz-fGIgf",
  "1H8Jlnsrld2dcGyDy_GGjw0E2ok9kETf1",
  "1tgxnQq-H9sas4puOcOyGuTP20niMJfoW",
  "1dmsvVS-Z1ZLJnI4wmsGJ7R3LKKsAyQzq",
  ];
  
  const displayImage = (image) => {
    console.log(image);
  };
  
  export default GebragImg;
  