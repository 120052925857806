import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Newsletter } from "./Newsletter";

export const MailchimpForm = () => {

  return (
    <>
      <MailchimpSubscribe
        render={() => (
          <Newsletter
            />
        )}
        />
    </>
  )
}
