const YoutubeData = [
  {
    link: "https://www.youtube.com/watch?v=l5rJabWFKFU",
  },
  {
    link: "https://www.youtube.com/watch?v=zJP_nMQAuJM",
  },
  {
    link: "https://www.youtube.com/watch?v=oZJsHHX_wGg",
  },
  {
    link: "https://www.youtube.com/watch?v=Kd30UgdpIuc",
  },
  {
    link: "https://www.youtube.com/watch?v=nnJSR0nUs_Q",
  },
  {
    link: "https://www.youtube.com/watch?v=Hgby2dxrpno",
  },
  {
    link: "https://www.youtube.com/watch?v=MmQsdFySC5Q",
  },
  {
    link: "https://www.youtube.com/watch?v=nxwnLt_elL4",
  },
  {
    link: "https://www.youtube.com/watch?v=KUJ5nJanh3A",
  },
  {
    link: "https://www.youtube.com/watch?v=rnYxsbT2SRw",
  },
  {
    link: "https://www.youtube.com/watch?v=xU8WTlaE_Ds",
  },
  {
    link: "https://www.youtube.com/watch?v=NwYU8OTCOHE",
  },
  {
    link: "https://www.youtube.com/watch?v=FOoTnCrXVM4",
  },
  {
    link: "https://www.youtube.com/watch?v=rijlttDT0TM",
  },
  {
    link: "https://www.youtube.com/watch?v=qkG_5sBNZLA",
  },
  {
    link: "https://www.youtube.com/watch?v=XLceNx2riYc",
  },
  {
    link: "https://www.youtube.com/watch?v=TCk0_vhbJaI",
  },
  {
    link: "https://www.youtube.com/watch?v=PuxNd-4bEY0",
  },

];
  
export default YoutubeData;
  