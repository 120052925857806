const BelvicData = [
  {
    link: "https://www.tiktok.com/@belvic.id/video/7359132924731493637?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7358071440320498949?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7359444983621061893?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7361356185297079558?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7360899213821103365?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7360596857032019206?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7358788619965926661?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7358665853522447622?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7358401712282995973?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7358300103716752645?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347976346099879175?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347971417222204693?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347964595069406484?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347954100962757909?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347952232656063765?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347937240628202772?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@belvic.id/video/7347932902056807700?is_from_webapp=1&sender_device=pc"
  },
  ];
  
export default BelvicData;
  