import banten from "../../assets/img/Banten.png";
import kalfor from "../../assets/img/kalfor.png";
import nakama from "../../assets/img/nakama1.png";
import arsa from "../../assets/img/arsa.png";
import innofair from "../../assets/img/innofair.png";
import icn from "../../assets/img/ICN.png";
import apo from "../../assets/img/apo.png";
import finference from "../../assets/img/finference.png"
import asd from "../../assets/img/asd.png"

const events = [
    {
        date: "15-16 January 2024",
        eventTitle: "Kalfor Youth Innovation 2023",
        organization: "Nakama.id",
        jobdesc: "Photographer",
        iconSrc: kalfor,
        iconAlt: "nakama Icon",
        iconWidth: "50px"
    },
    {
        date: "23 December 2023",
        eventTitle: "Aku Sehat 6",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "21 December 2023",
        eventTitle: "Ceremony and Demo Day IT PLN Ideathon 2023",
        organization: "Nakama.id",
        jobdesc: "Photographer",
        iconSrc: nakama,
        iconAlt: "nakama Icon",
        iconWidth: "70px"
    },
    {
        date: "23 - 24 September 2023",
        eventTitle: "Sharing n Fun Educating 7",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "15 - 16 April 2023",
        eventTitle: "SAFE Pelita",
        organization: "CT ARSA Foundation",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: arsa,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "7 April 2023",
        eventTitle: "Gebrag Ramadhan",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "25 December 2022",
        eventTitle: "Aku Sehat 5",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "19 - 20 November 2022",
        eventTitle: "SAFE Collab",
        organization: "Komunitas ARSA Banten X Bogor",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "15 April 2022",
        eventTitle: "Gebrag Ramadhan",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "22 January 2022",
        eventTitle: "Aku Sehat 4",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "13 - 27 November 2021",
        eventTitle: "Finference 2021",
        organization: "FINference",
        jobdesc: "Video Editor",
        iconSrc: finference,
        iconAlt: "icn Icon",
        iconWidth: "50px"
    },
    {
        date: "1 - 9 October 2021",
        eventTitle: "Actuarial Science Day 2021",
        organization: "Actuarial Science Day",
        jobdesc: "Animator and Video Editor",
        iconSrc: asd,
        iconAlt: "icn Icon",
        iconWidth: "50px"
    },
    {
        date: "29 - 30 August 2020",
        eventTitle: "ICN 2020 Online",
        organization: "Indonesia Culture National 2020",
        jobdesc: "Video Editor",
        iconSrc: icn,
        iconAlt: "icn Icon",
        iconWidth: "50px"
    },
    {
        date: "14 - 15 March 2020",
        eventTitle: "Sharing n Fun Educating 6",
        organization: "Komunitas ARSA Banten",
        jobdesc: "Photographer and Social Media Officer",
        iconSrc: banten,
        iconAlt: "banten Icon",
        iconWidth: "50px"
    },
    {
        date: "11 December 2019",
        eventTitle: "Pre-event ICN 2020",
        organization: "Indonesia Culture National 2020",
        jobdesc: "Photographer",
        iconSrc: icn,
        iconAlt: "icn Icon",
        iconWidth: "50px"
    },
    {
        date: "13 - 14 November 2019",
        eventTitle: "Food Exhibition ICN 2020",
        organization: "Indonesia Culture National 2020",
        jobdesc: "Photographer",
        iconSrc: icn,
        iconAlt: "icn Icon",
        iconWidth: "50px"
    },
];

export default events;
