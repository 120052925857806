import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import apple from "../assets/img/apple.png";
import { TimelineInternElement } from "./TimelineIntern";
import { FaGraduatuionCap } from 'react-icons/fa';

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import interns from "../components/data/internsData";
import organizations from "./data/organizationsData";
import { TimelineOrganizationElement } from "./TimelineOrganization";
import events from "./data/eventsData";
import { TimelineEventElement } from "./TimelineEvent";

export const Experience = () => {

  return (
    <section className="project" id="experiences">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Experiences</h2>
                <p>My journey in the tech world has been a mix of valuable experiences. I learned hands-on during an internship, where I worked on real projects. The bootcamp I attended was intense but super rewarding, helping me dive into the latest technologies.</p> 
                <p> Being part of tech organizations let me contribute to cool projects and work with talented people. Participating in events boosted my networking skills and kept me updated on industry trends. These experiences shaped my problem-solving skills, blending tech know-how with a collaborative mindset, as you'll see in my evolving portfolio.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Internships</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Clubs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Events</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <VerticalTimeline lineColor="#FFF">
                      {interns.map((intern, index) => (
                        <TimelineInternElement
                          key={index}
                          {...intern}
                        />
                      ))}
                    </VerticalTimeline>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <VerticalTimeline lineColor="#FFF">
                        {organizations.map((organization, index) => (
                          <TimelineOrganizationElement
                            key={index}
                            {...organization}
                          />
                        ))}
                      </VerticalTimeline>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <VerticalTimeline lineColor="#FFF">
                        {events.map((event, index) => (
                          <TimelineEventElement
                            key={index}
                            {...event}
                          />
                        ))}
                      </VerticalTimeline>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
