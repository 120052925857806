import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Experience } from "./components/Experience";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";

const Home = () => {
    return (
      <div className="App">
        {/* <NavBar /> */}
        <Banner />
        <Skills />
        <Projects />
        <Experience />
        <Contact />
        <Footer />
      </div>
    );
  }
  
  export default Home;