const kalforimg = [
    "14eBXXC-wL5bLM2Qbshel6hcqTz08D4SY",
    "1oTWGkmI4-fl6gVVOVFdOAAsPHHXq0y_q",
    "11KrHQYafTsV_Kzmxq2cyQJ6ORCFyO-9L",
    "1Zq0RoqWlZQJh4lSznhqwJsV5GrYSOmAK",
    "1ARfI7bXXVieHALMXon0dGkLeKJivhpuW",
    "11G_3bfBZ4ATew993M3rLS6ZzuNuqtadd",
    "1GcuxqvJz7L2YWtInzgsI-_iNMD6AL3eo",
    "1f6HQycvEEIlDiqHyEFaqIBLbk7d5ykZZ",
    "1UamoWLA41HT15gOdAc4B9xMzPu6M_b3_",
    "1_hMgJjShtPMklp5j6r1URUTPP8bRzi-y",
    "1QtWLj5WvIii3j7ueR7EFZwhe61VCs-Zy",
    "1EAlTxNUU6Mn_mqIfQCBQvWf7KpxtyUyV",
    "1lYX-0i83DXkfDTcfG27shOm_jOhhtq3w",
    "1gYJhDcxHtQFLAIq6PvKLyJ8T6JftM25T",
    "1ccArdxrMz4YE3hlAivV48eZBXvl7WPZu",
    "16nwcEfq4Dbcrd3QfedOchyazTaJolUzJ",
    "1FxX_vOQgAhfOedg_tRKBCW-lV8SLKj1a",
    "1zCV1ySdQbsSqYRS2EogZgJ8hxvaXHcnR",
    "1FKEFxRUJ1yAt0sYr1DH5lB9CGLtq3MXd",
    "1wFqtVXRWtL_acO9tzPnQNILYk0GIEr0n",
  ];
  
  const displayImage = (image) => {
    console.log(image);
  };
  
  export default kalforimg;
  