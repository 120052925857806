import { Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';

export const ProjectCard = ({ title, description, imgUrl, goto, link }) => {
  const handleButtonClick = () => {
    if (goto) {
      window.open(goto, '_blank');
    }
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <div>
            <button style={{ color: 'white',  textDecoration: 'underline'  }} onClick={handleButtonClick}>
              {link} <ArrowRightCircle size={25} />
            </button>
          </div>
        </div>
      </div>
    </Col>
  )
}
