import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "yet-another-react-lightbox/styles.css";
import { TikTokEmbed } from 'react-social-media-embed';

export const TiktokView = ({ link }) => {
    return(
        <Col 
        style={{marginTop: "20px"}}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TikTokEmbed url={link} width={328} />
            </div>
        </Col>
    )
}