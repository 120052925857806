import { useState } from "react";
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import sakulaundry from "../../../assets/img/sakulaundry.jpeg";
import { TiktokView } from "../tiktokView.js";

import "yet-another-react-lightbox/styles.css";
import SakuData from "./sakuData.js";

const CircleContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto; /* Center the image within the circular container */
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SakuPage = () => {
    const [index, setIndex] = useState(-1);
    const handleClick = (index, item) => setIndex(index);
    return (
      <Container>
        <div className="detail"></div>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "100px" }}
        >
            <Col xs={12} md={8} lg={30}>
                <CenteredContent>
                    <CircleContainer>
                        <CircleImage src={sakulaundry} alt="Your Image" />
                    </CircleContainer>
                    <h1>SakuLaundry</h1>
                    <p>Videographer & Video Editor</p>
                </CenteredContent>
            </Col>
            <Row
                style={{ marginTop: "50px"}}
            >
                {
                  SakuData.map((videos, index) => {
                    return (
                      <TiktokView
                        key={index}
                        {...videos}
                      />
                    )
                  })
                }
            </Row>
        </Row>
      </Container>
    );
  };
  

export default SakuPage;
