import { Col, Row } from "react-bootstrap";

export const Newsletter = () => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row style={{ textAlign: "center" }}>
          <h3>
            "I hope you are interested in my portfolio, and in the future, we can collaborate on a project together. <br></br> <br></br>Don't forget to connect with me on LinkedIn."
          </h3>
        </Row>
      </div>
    </Col>
  );
};

