import React from 'react';
import { Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';

export const PhotographyCard = ({ title, description, imgUrl, goto, link }) => {
  const handleButtonClick = () => {
    if (goto) {
      window.location.href = goto;  // Redirect to the specified link
    }
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={`https://drive.google.com/thumbnail?id=${imgUrl}&sz=w2000`} alt={title} className="project-image" />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <div>
            <button style={{ color: 'white', textDecoration: 'underline' }} onClick={handleButtonClick}>
              {link} <ArrowRightCircle size={25} />
            </button>
          </div>
        </div>
      </div>
    </Col>
  )
};
