import { useState } from "react";
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import nakama from "../../assets/img/nakama.png";
import sorry from "../../assets/img/sorry.png";
import { Gallery } from 'react-grid-gallery';
import demodayimg from './DemoDayImg';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const CircleContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;
  display: block;
  margin: auto; /* Center the image within the circular container */
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const DemoDay = () => {
    const [index, setIndex] = useState(-1);
  
    const handleClick = (index, item) => setIndex(index);

    const images = demodayimg.map((imageId) => ({
        src: `https://drive.google.com/thumbnail?id=${imageId}&sz=w2000`,
        width: 5184,
        height: 3456,
      }));
  
    return (
      <Container>
        <div className="detail"></div>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "100px" }}
        >
            <Col xs={12} md={8} lg={30} className="text-center">
                <CenteredContent>
                    <CircleContainer>
                        <CircleImage src={nakama} alt="Your Image" />
                    </CircleContainer>
                    <h1>Demo Day IT PLN Ideathon 2023</h1>
                    <p>21 December 2023</p>
                    <p>Organized by: Nakama.id</p>
                
                </CenteredContent>
            </Col>
            <Col xs={12} md={8} lg={30} className="text-center" style={{ marginTop: '60px' }}>
                <CenteredContent>
                    <CircleContainer>
                        <CircleImage src={sorry} alt="Your Image" />
                    </CircleContainer>
                    <h1>Will Update Soon</h1>
                
                </CenteredContent>
            </Col>
            <Gallery
                    images={images}
                    onClick={handleClick}
                    enableImageSelection={false}
                />
            <Lightbox
                slides={images}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
        </Row>
      </Container>
    );
  };
  

export default DemoDay;
