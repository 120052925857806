const SakuData = [
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7415449134703873288?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7414388918772059397?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7412502862502087954?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7411013106010180865?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7410084509686828289?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7406547177423539457?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7389563529742912784?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7389547812259712257?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7389538027996990737?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7386973458325884177?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7385485581800819984?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7384328829700951297?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7384294529400114449?is_from_webapp=1&sender_device=pc"
  },


  {
    link: "https://www.tiktok.com/@saku.laundry/video/7402504447861017857?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7398039827637193992?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7398034849593855239?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7398009394497146120?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7394716052875267344?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7394686566683200785?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7394681068181409025?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7393242956850875665?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7393221064446266641?is_from_webapp=1&sender_device=pc"
  },



  {
    link: "https://www.tiktok.com/@saku.laundry/video/7369454180014001429?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7370177399989767441?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7372216915504237832?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7371364924389362952?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7372229754969214216?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7373882918910872839?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7373893080212098311?is_from_webapp=1&sender_device=pc"
  },
  ];
  
export default SakuData;
  