const SAFE7Img = [
  "19YzNv_J76mdeqQiVRJv4lmev__13XMSB",
  "1VhFwFKQyIKVXd8HlBA0t9Awi7-3m7jkU",
  "1omH1_WWZg1moNnfu1Gy1PZU2dsfVxRGr",
  "11tfPN1XvokNTNweYCLnuv16Un27yycFW",
  "1o7PKcDjlvYEZpQcsxscS38x5rflHM1Ro",
  "1tnwRQ1gE-aw4pwz4yFZNmMqP07ThuNmW",
  "1zkdNfjWrur24Cyoz1pg7BXRYLtIl1O7a",
  "1VwSX_7cKWMMNyPgaolbNvNEgt8IqTcgG",
  "1wJpizIjmI2EkHDPtK1YQLz0VQfehgWZf",
  "1CXOmDGewHb4qKtX5xznqfkOGb689ozd2",
  "1MGEto381JSQWYFUxOXDqm_2oZhrzICZm",
  "1edkaa9xYLOJWWr9NuiV_-sSVgDvtDQLA",
  "1573ow_LWptT3il9iZKD3hTNx-mtXyXNj",
  "1QilfAdn6xvrXwofd5CyICWrANxReNoRe",
  "1YN4TSYoDgW28AQVBjyZ4S6kCLJR8cXCE",
  "1R24q8Be_aHTgJScg_dOF8sAwjZzIntlj",
  "1DOAvAbqWhsbs-sXLQQwTVfTcz4jJ496P",
  "1n-hH3C-hE4Y0D57y3GH03e72dJUVKyfe",
  "1HSVEhScWG2Kyr-foZnftx7W9ALX1H3kZ",
  "1uzBMIs_KR3VtVWZvJ4qMqcTBA0ohaOKS",
  ];
  
  const displayImage = (image) => {
    console.log(image);
  };
  
  export default SAFE7Img;
  