const SAFEPelitaImg = [
  "1q1LB1UTksAqgltTKlt5-_NnhZ6dxQ2qa",
  "1Duh10eKM_Z1EZGaPdXiixsDegyKiUBI0",
  "1a1ehN3t2XkS4xwTLs_Ye9jtpEtCOuOo9",
  "1NA0o4whsn_32ThTg8V1337OnJ7hKZ8zB",
  "1bwA5HgtImfdq3uUERvHyF5PZ-IOZjFzv",
  "1qaVAr_dhfc6TWu09eAMcGzKsyBQFzlD1",
  "1-ganGcQqZ07BOPXGqR5MFlYoChqoq_iw",
  "1kjbEnHKTZHKavIoEIEg1eflS9DWk5qgz",
  "1gFWnGwc0A0J5haww5NfYPSZoZkFPqc3x",
  "1UjjmDr9StT2aw9m6oi_DDir1cJLKT77b",
  "1ya4r78gUUWT5zWZqrdKXk5xLdMiBAaez",
  "1f8xeWbgUodjGvISa0-TKbiSrvfm4fEi2",
  "1-ukX7N_51M1DxVILntKmmUahpCoc2s1Z",
  "11uyRWwKgLjk-mQqUB5hWlCeH-MaxQx0F",
  "14ufOpHuKooYwQ2oLYN7To82ki_ug1T55",
  "1WhDry4EpajH8g2R7PyupKElvyxi11VEX",
  "14ymUX945FNycakoR3F7hkIlmI1NDXMJf",
  "1SwJSAcM5BmU4rDtT_qliydcQWne--GA1",
  ];
  
  const displayImage = (image) => {
    console.log(image);
  };
  
  export default SAFEPelitaImg;
  