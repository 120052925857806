import { useState } from "react";
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import banten from "../../assets/img/Banten.png";
import { Gallery } from 'react-grid-gallery';
import akusehat6img from './akuSehat6img';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const CircleContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(147, 62, 129), rgb(73, 46, 158));
`;

const CircleImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;
  display: block;
  margin: auto; /* Center the image within the circular container */
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const AkuSehat6 = () => {
    const [index, setIndex] = useState(-1);
  
    const handleClick = (index, item) => setIndex(index);

    const images = akusehat6img.map((imageId) => ({
        src: `https://drive.google.com/thumbnail?id=${imageId}&sz=w2000`,
      }));
  
    return (
      <Container>
        <div className="detail"></div>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "100px" }}
        >
            <Col xs={12} md={8} lg={30} className="text-center">
                <CenteredContent>
                    <CircleContainer>
                        <CircleImage src={banten} alt="Your Image" />
                    </CircleContainer>
                    <h1>Aku Sehat 6</h1>
                    <p>23 December 2023</p>
                    <p>Organized by: Komunitas ARSA Banten</p>
                
                </CenteredContent>
            </Col>
            <Gallery
                    images={images}
                    onClick={handleClick}
                    enableImageSelection={false}
                />
            <Lightbox
                slides={images}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
        </Row>
      </Container>
    );
  };
  

export default AkuSehat6;
