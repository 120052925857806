const KanayaData = [
  {
    link: "https://www.tiktok.com/@kanayaskin_id/video/7360614273917259014?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@kanayaskin_id/video/7359480692385893637?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@kanayaskin_id/video/7359116809720827141?is_from_webapp=1&sender_device=pc"
  },
  ];
  
export default KanayaData;
  