import React from "react";
import reactjs from "../assets/img/reactjs.png";
import swift from "../assets/img/swift.png";
import flutter from "../assets/img/flutter-icon.png";
import django from "../assets/img/django.png";
import figma from "../assets/img/figma.png";
import premiere from "../assets/img/premiere.png";
import after from "../assets/img/after.png";
import html from "../assets/img/html.png";
import nodejs from "../assets/img/nodejs.png";
import PS from "../assets/img/PS.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                I bring a robust skill set to the table.<br></br> My track
                record of successfully applying these skills and demonstrates{" "}
                <br></br> my capability and commitment to achieving outstanding
                results
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={reactjs} alt="ReactJS" />
                  <h5>Web Development</h5>
                </div>
                <div className="item">
                  <img src={swift} alt="Swift" />
                  <h5>iOS Development</h5>
                </div>
                <div className="item">
                  <img src={flutter} alt="Flutter" />
                  <h5>Android Development</h5>
                </div>
                <div className="item">
                  <img src={figma} alt="Figma" />
                  <h5>App and Web Design</h5>
                </div>
                <div className="item">
                  <img src={django} alt="Django" />
                  <h5>Backend Development</h5>
                </div>
                <div className="item">
                  <img src={premiere} alt="Premiere" />
                  <h5>Editing Video</h5>
                </div>
                <div className="item">
                  <img src={html} alt="HTML" />
                  <h5>Web Development</h5>
                </div>
                <div className="item">
                  <img src={nodejs} alt="Node.js" />
                  <h5>Backend Development</h5>
                </div>
                <div className="item">
                  <img src={PS} alt="Photoshop" />
                  <h5>App and Web Design</h5>
                </div>
                <div className="item">
                  <img src={after} alt="After Effects" />
                  <h5>Editing Video</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="Color Sharp"
      />
    </section>
  );
};
