import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import resume from '../assets/files/cv.pdf';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import {
    BrowserRouter as Router, Route, Routes
  } from "react-router-dom";

import Home from "../Home";
import Kalfor from "./detailPages/Kalfor";
import AkuSehat6 from "./detailPages/akuSehat6";
import DemoDay from "./detailPages/DemoDay";
import SAFE7 from "./detailPages/SAFE7";
import SAFEPelita from "./detailPages/SAFEPelita";
import Gebrag from "./detailPages/Gebrag";
import SocialMedia from "./videoPages/socialMedia";
import Podcast from "./videoPages/podcast";
import Pitching from "./videoPages/pitching";

import Belvic from "./videoPages/socialMedia/belvicPage";
import Maheswara from "./videoPages/socialMedia/maheswaraPage";
import Kanaya from "./videoPages/socialMedia/kanayaPage";
import SakuLaundry from "./videoPages/socialMedia/sakuPage";
// import Selca from "./videoPages/socialMedia/selcaPage";
// import DeepTalk from "./videoPages/socialMedia/deeptalkPage";
// import Evoria from "./videoPages/socialMedia/evoriaPage";
// import ArsaBanten from "./videoPages/socialMedia/arsabantenPage";
// import Toyago from "./videoPages/socialMedia/toyagoPage";


export const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }
    
    return (
        <Router>
            <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
                <Container>
                <Navbar.Brand href="/">
                    {/* <img src={logo} alt="Logo" /> */}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                    <Nav.Link href="/#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                    <Nav.Link href="/#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                    <Nav.Link href="/#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
                    <Nav.Link href="/#experiences" className={activeLink === 'experiences' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experiences')}>Experiences</Nav.Link>
                    </Nav>
                    <span className="navbar-text">
                    <div className="social-icon">
                        <a href="https://www.linkedin.com/in/gaizka-valencia-047897174/"><img src={navIcon1} alt="" /></a>
                        <a href="https://www.facebook.com/gaizka.valencia"><img src={navIcon2} alt="" /></a>
                        <a href="https://www.instagram.com/aga_gv/"><img src={navIcon3} alt="" /></a>
                    </div>
                    <HashLink to='https://www.linkedin.com/in/gaizka-valencia-047897174/'>
                        <button className="vvd"><span>Let’s Connect</span></button>
                    </HashLink>
                    </span>
                </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Kalfor-Youth-Innovators-2024" element={<Kalfor />} />
                <Route path="/Aku-Sehat-6" element={<AkuSehat6/>} />
                <Route path="/DemoDay-IT-PLN-Ideathon-2023" element={<DemoDay/>} />
                <Route path="/Sharing-n-Fun-Educating-7" element={<SAFE7/>} />
                <Route path="/SAFE-Pelita" element={<SAFEPelita/>} />
                <Route path="/Gebrag-Ramadhan" element={<Gebrag/>} />
                <Route path="/Video-Editor/Social-Media" element={<SocialMedia/>} />
                <Route path="/Video-Editor/Podcast" element={<Podcast/>} />
                <Route path="/Video-Editor/Product-Pitch" element={<Pitching/>} />

                <Route path="/Video-Editor/Social-Media/Belvic" element={<Belvic/>} />
                <Route path="/Video-Editor/Social-Media/Maheswara" element={<Maheswara/>} />
                <Route path="/Video-Editor/Social-Media/Kanaya" element={<Kanaya/>} />
                <Route path="/Video-Editor/Social-Media/Saku-Laundry" element={<SakuLaundry/>} />
                {/* <Route path="/Video-Editor/Social-Media/Selca" element={<Selca/>} /> */}
                {/* <Route path="/Video-Editor/Social-Media/DeepTalk" element={<DeepTalk/>} /> */}
                {/* <Route path="/Video-Editor/Social-Media/Evoria" element={<Evoria/>} /> */}
                {/* <Route path="/Video-Editor/Social-Media/ARSA-Banten" element={<ArsaBanten/>} /> */}
                {/* <Route path="/Video-Editor/Social-Media/Toyago" element={<Toyago/>} /> */}
                

            </Routes>
        </Router>
    )
}