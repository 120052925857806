const akusehat6img = [
  "1sMWh51ylv8B6tMGr_X5awmF66c6Ap28C",
  "1DCmiPvl6mp0vx-sopc7-iX2WPmZ4MamB",
  "1R-9eLO0uEaO8f8pkHcNn4zsd0-zX_nT6",
  "1X5JW-XnH7li-pmGjSJme9UWgKCi93LDB",
  "1eFz_-HPzCR-EH8P569n6SM23LCrstTrV",
  "1q0pLsAAgItpkKoj-ZtNo-b4lPl19Lv9-",
  "1Ve7MVm68yWPmqh6rdW5odjX8F5-y7T7c",
  "1blB8igANQyN9ytdCzElGQ2S6dX70Z7cu",
  "1mGdxGDhXZtNJ2JM168Df9-8Jc3ooS0vr",
  "1Lc1IUBT0RN2a-xDDDO2q0iLRq0UX0cm-",
  "1trGNl4tyySL-Uy3UNHHF04ryhrj2APG0",
  "14q0WN5Suxni8x1AGH_7HggIJy6zpRMKD",
  "14BcKwJe6U03sgKxu6vifqik1coFeV-DL",
  "1HBw4GAoE36lrF0YX84fxDYfSnA8kpQHK",
  "1wcKYm9yeJGv05xaUhCxXc2BxWzTNvTJX",
  "1-Uu_cNyyQWCsayMfNcZqiqRXx2Faskdp",
  "1yZU5Fpvpl4uk2FWImNd6kfECvyx7PEzC",
  "18JzM7iUiCw9oKRLjbHa-74XMTTDTI_Pj",
  "1gnFAEJLpMl-T_rVt3MGztOWVB0fDBHyY",
  "1L3Eywg1sTq5Mt9WyMHEXr8WqfSVd21TY",
  "1R-2MNqwdk9sZiTeO6oo0UbhBWRX3Om-d",
  ];
  
  const displayImage = (image) => {
    console.log(image);
  };
  
  export default akusehat6img;
  